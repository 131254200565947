@layer base {
    html{
        @apply scroll-smooth;
    }

    body {
        @apply font-light font-fira-sans overflow-x-hidden text-brown text-16 lg:p-20;
    }
}

@layer utilities {
    [x-cloak] {
        display: none !important;
    }
}
