@tailwind base;
@tailwind components;
@tailwind utilities;

@import "base";
@import "import-fonts";
@import "components";
@import "wysiwyg";
@import "effects";
@import "overlay";
@import "container";
@import "swiper";
@import "numbers";
@import "loading-animation";
@import "lightbox";

