@layer utilities {
  .animation-zoom-out{
    animation: zoom-out 1000ms ease-out both;
    @apply origin-center;
  }

  @keyframes zoom-out {
    0%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(1);
    }
  }

  .animation-fade-in{
    animation: fade-in 500ms ease-out both;
  }

  @keyframes fade-in {
    0%{
        opacity: 0;
        transform: translateY(40px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
  }

  @for $i from 1 through 10 {
    .animation-delay-#{$i * 100} {
      animation-delay: #{100 * $i}ms;
    }
  }
}
