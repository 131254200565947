.hover-underline {
    @apply relative w-fit;

    &::after {
        content: "";

        @apply absolute left-0 bottom-0 right-0 h-0.5 bg-current origin-right scale-x-0 transition-transform duration-300 ease-in-out;
    }

    &:hover {
        &::after {
            @apply scale-x-100 origin-left;
        }
    }

    &.hover-underline-thin::after{
        @apply h-px;
    }
}
