@font-face {
    font-family: "Fira Sans";
    src: url("../assets/fonts/Fira-Sans/FiraSans-Light.woff") format("woff"),
        url("../assets/fonts/Fira-Sans/FiraSans-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Fira Sans";
    src: url("../assets/fonts/Fira-Sans/FiraSans-Regular.woff") format("woff"),
        url("../assets/fonts/Fira-Sans/FiraSans-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vollkorn";
    src: url("../assets/fonts/Vollkorn/Vollkorn-Regular.woff") format("woff"),
        url("../assets/fonts/Vollkorn/Vollkorn-Regular.woff") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vollkorn";
    src: url("../assets/fonts/Vollkorn/Vollkorn-SemiBold.woff") format("woff"),
        url("../assets/fonts/Vollkorn/Vollkorn-SemiBold.woff") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Vollkorn";
    src: url("../assets/fonts/Vollkorn/Vollkorn-Black.woff") format("woff"),
        url("../assets/fonts/Vollkorn/Vollkorn-Black.woff") format("woff2");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
