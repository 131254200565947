.wysiwyg {
    strong {
        @apply font-vollkorn font-semibold;
    }

    &.fira-only strong {
        @apply font-fira-sans font-regular;
    }

    ul {
        @apply pl-1;

        li::before {
            content: "";
            @apply inline-block w-1 h-1 mb-1.5 mr-3 rounded-full bg-black;
        }
    }
}
